.thirds {
	display: flex;
	flex-wrap: wrap;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
	.main-content {
		flex: 2;
		padding-left: 15px;
	}
	aside {
		flex: 1;
		padding-right: 15px;
		display: flex;
	}
}