@font-face {
  font-family: 'Abril Fatface', cursive;
  @import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');
}

@font-face {
  font-family: 'Amiri', serif;
  src: url('https://fonts.googleapis.com/css2?family=Amiri:ital,wght@0,400;0,700;1,400;1,700&display=swap');
}

body {
  margin: 0;
  font-family: 'Lato', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  color: #626363;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// CREATE VARIABLES
// PRIMARY COLOUR / SECONDARY COLOUR / SERIF FONT? / SANS-SERIF FONT

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  margin: auto;
  max-width: 1200px;
  // min-height: 100vh;
  // padding: 2rem;
  // transform: translateZ(0);
  // will-change: margin-left;
  display: block;
  box-sizing: border-box;
  @media (min-width: 640px) {
    padding: 0 1rem;
  }
}

h1 {
  font-weight: 700;
  font-size: .75rem;
  line-height: 1.5;
  letter-spacing: 2px;
  text-transform: uppercase;
}

h2, h3, h4, h5, h6 {
  font-family: 'Amiri', serif;
  font-weight: 400;
}

h2 {
  font-size: 3.75rem;
  line-height: 1;
  color: #000;
  margin-top: 0;
}

h3 {
  font-size: 48px;
}

p {
  line-height: 22px;
  margin-top: 0;
}

label {
  text-transform: uppercase;
  letter-spacing: 1px;
}

input, textarea {
  box-sizing: border-box;
  width: 100%;
  background: transparent;
  color: #000;
  padding: 0;
  border: none;
  border-bottom: 1px solid #000;
  transition: all .25s ease;
  font-size: 1rem;
  font-family: inherit;
  line-height: 1.5;

  &:focus-visible {
    outline: none;
    border-bottom: 1px solid purple;
  }
}

input {
  height: 50px;
  line-height: 80px;
}

.btn {
  border: 1px solid #000;
  width: auto;
  padding: 0.5rem 2rem;
  line-height: 1;
  cursor: pointer;
  background-color: #800080;
  color: #fff;
  transition: all 0.25s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: 1px solid purple;
  &:hover {
    background-color: rgb(128 0 128 / 90%);
    border-color: #fff;
  }
}

.feature-wrapper {
  padding-top: 85px;
  padding-bottom: 85px;
  display: grid;
  &.w-50 {
    grid-template-columns: 1fr 1fr;
  }
}

.logo {
  font-size: 30px;
  text-transform: lowercase;
  position: relative;
  a {
    text-decoration: none;
    color: #626363;
  }
  span {
    font-weight: bold;
  }
  .underline {
    display: block;
    position: absolute;
    height: 3px;
    background-color: #000;
    left: 0;
  }
}

.timeline {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 40px 0;
  box-sizing: border-box;
  &::after {
    background-color: #e17b77;
    content: '';
    position: absolute;
    left: calc(50% - 2px);
    width: 4px;
    height: 100%;
  }
}

.timeline-item {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
  padding-right: 30px;
  position: relative;
  width: 50%;
  box-sizing: border-box;
  &:nth-child(odd) {
    align-self: flex-end;
    justify-content: flex-start;
    padding-left: 30px;
    padding-right: 0;
  }
}

.timeline-content {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  border-radius: 5px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 15px;
  position: relative;
  width: 400px;
  max-width: 70%;
  text-align: right;
  &::after {
    content: ' ';
    background-color: #fff;
    box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.2);
    position: absolute;
    right: -7.5px;
    top: calc(50% - 7.5px);
    transform: rotate(45deg);
    width: 15px;
    height: 15px;
  }
  .tag {
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    top: 5px;
    left: 5px;
    letter-spacing: 1px;
    padding: 5px;
    position: absolute;
    text-transform: uppercase;
  }
  .circle {
    background-color: #fff;
    border: 3px solid #e17b77;
    border-radius: 50%;
    position: absolute;
    top: calc(50% - 12px);
    right: -43px;
    width: 20px;
    height: 20px;
    z-index: 100;
  }
}

.timeline-item:nth-child(odd) {
  .timeline-content {
    text-align: left;
    align-items: flex-start;
    .tag {
      left: auto;
      right: 5px;
    }
    .circle {
      right:auto;
      left: -43px;
    }
  }
}

.timeline-item:nth-child(odd) .timeline-content::after {
  right: auto;
  left: -7.5px;
  box-shadow: -1px 1px 1px rgba(0, 0, 0, 0.2);
}

// .timeline-date {
//   font-weight: bold;
// }

// .timeline-event {
//   font-size: 1.2em;
// }

// .timeline-description {
//   margin-top: 5px;
//   color: #333;
// }

.contact-form {
  > div {
    display: block;
    margin-bottom: 2.5rem;
  }
  label {
    display: block;
    padding-bottom: .25rem;
  }
}

// .media {
//   margin: 0;
//   postion: relative;
//   &:before {
//     content: '';
//     position: absolute;
//     right: 0;
//     top: 0;
//     background-color: red;
//     z-index: -1;
//   }
// }

.timeline-date {
  text-transform: uppercase;
  letter-spacing: 1.5px;
  font-size: 0.7rem;
  font-weight: 700;
  display: block;
  margin-bottom: .5rem;
}

.timeline-event {
  margin-bottom: .5rem;
  font-family: 'Amiri', serif;
  font-weight: 700;
  font-size: 1.2rem;
}

.timeline-description {
  line-height: 1.4;
}